import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo-light.png";
import logo_dark from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "./ThemeContext";

import { toastAlert } from "../lib/toastAlert";
import { getHomePage } from "../api/users";

export default function Footer() {
  //redux
  const { isAuth } = useSelector((state) => state.auth);
  const siteDoc = useSelector((state) => state.siteSetting);

  const { theme } = useTheme();

  const { isStake, isTrade } = useSelector((state) => state.account);

  const handleTrade = () => {
    if (!isTrade) {
      toastAlert("error", "Trade Access Blocked", "login");
    }
  };

  const handleStake = () => {
    if (!isStake) {
      toastAlert("error", "Stake Access Blocked", "login");
    }
  };

  const [data, setData] = useState({});

   //function
   const getHomeDetailas = async () => {
    try {
      const { status, result } = await getHomePage();
      if (status) {
        setData(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    getHomeDetailas();
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel_top">
          <div className="row">
            <div className="col-lg-3">
              <Link className="navbar-brand" to="/">
                {theme === "light" ? (
                  <img src={logo} className="img-fluid" alt="Light logo" />
                ) : (
                  <img src={logo_dark} className="img-fluid" alt="Dark logo" />
                )}
              </Link>
              <div className="footer_abt_content">
                {siteDoc?.stakeAccess && (
                  <Link
                    to={isAuth && isStake ? "/staking" : "/login"}
                    onClick={handleStake}
                  >
                    Staking
                  </Link>
                )}
                {/* {
                  siteDoc?.tradeAccess &&
                  <Link
                    to={isAuth && isTrade ? "/trade" : "/login"}
                    onClick={handleTrade}
                  >
                    Trade
                  </Link>
                } */}
                {siteDoc?.walletAccess && (
                  <>
                    <Link to={isAuth == true ? "/wallet" : "/login"}>
                      Wallet
                    </Link>
                    {/* <Link to={isAuth == true ? "/wallet" : "/login"}>
                      Exchange
                    </Link> */}
                  </>
                )}
                <Link to={isAuth == true ? "/support-ticket" : "/login"}>
                  Support Ticket
                </Link>
                {/* <a href="#">About Us</a> <span> |  </span>
                <a href="#">Legal Documents</a> <span> |  </span>
                <a href="#">Contact Us</a> */}
              </div>
            </div>
            <div className="col-lg-2 footer_participant">
              <div className="mb-3">
                <h5>32{data?.Totaluser}+</h5>
                <p>All Participants</p>
              </div>
              <div className="mb-1">
                <h5>{data?.totalInterest}+</h5>
                <p>Total unstaked</p>
              </div>
            </div>
            <div className="col-lg-4">
              <p>
                We take pride in our client success stories, where our
                innovative strategies and flawless execution have been crucial
                in achieving their business goals and exceeding expectations.
              </p>
            </div>
            <div className="col-lg-3 mt-3 mt-lg-0">
              <h3>Stay in Touch</h3>
              <ul className="footer_social_links">
                {/* {siteDoc && siteDoc.facebookLink && (
                  <li>
                    <a href={siteDoc.facebookLink} target="_blank">
                      <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.9669 29.088V16.7991H14.0573L14.8439 11.7227H9.9669V8.41235C9.92707 8.03099 9.97439 7.64558 10.1053 7.28515C10.2362 6.92472 10.4473 6.59867 10.7226 6.33158C10.9979 6.06449 11.3303 5.86331 11.6948 5.74322C12.0592 5.62314 12.4462 5.58728 12.8265 5.63833H15.0475V1.31087C13.7465 1.10178 12.432 0.987444 11.1144 0.96875C7.08881 0.96875 4.46983 3.40062 4.46983 7.80206V11.6764H0V16.7529H4.46983V29.0418L9.9669 29.088Z" fill="white" />
                      </svg>
                    </a>
                  </li>
                )} */}
                {/* {siteDoc && siteDoc.twitterUrl && (
                  <li>
                    <a href={siteDoc.twitterUrl} target="_blank">
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8644 9.33475L20.8404 0.0683594H18.9525L12.0265 8.14365L6.49376 0.0683594H0.0625L8.43222 12.2419L0.0625 21.9524H1.96059L9.27021 13.4531L15.2068 21.9524H21.5976L12.9149 9.32465L12.8644 9.33475ZM10.2697 12.363L9.42166 11.1416L2.67741 1.50171H5.58511L11.0269 9.28429L11.875 10.4956L18.9423 20.5897H16.0347L10.2596 12.3327L10.2697 12.363Z" fill="white" />
                      </svg>
                    </a>
                  </li>
                )} */}

                {siteDoc && siteDoc.whatsappLink && (
                  <li>
                    <a href={siteDoc.whatsappLink} target="_blank">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.25 27.7422L2.21904 20.3996C0.633525 17.5394 0.135306 14.2049 0.815807 11.0079C1.49631 7.81089 3.30989 4.96589 5.92373 2.995C8.53756 1.0241 11.7763 0.0595429 15.0457 0.278266C18.315 0.496989 21.3956 1.88433 23.7222 4.18575C26.0487 6.48716 27.4651 9.54825 27.7114 12.8073C27.9578 16.0663 27.0176 19.3046 25.0633 21.9279C23.109 24.5512 20.2718 26.3837 17.0723 27.0889C13.8728 27.7941 10.5257 27.3247 7.64511 25.767L0.25 27.7422ZM8.08144 23.0219L8.55132 23.3009C10.8786 24.6883 13.6345 25.178 16.2989 24.6775C18.9633 24.1771 21.3519 22.7212 23.0138 20.5846C24.6758 18.4479 25.4961 15.7784 25.3199 13.0799C25.1438 10.3813 23.9834 7.84042 22.0577 5.93676C20.132 4.0331 17.5744 2.89837 14.8674 2.74674C12.1604 2.59511 9.49141 3.43709 7.36417 5.11373C5.23693 6.79037 3.79861 9.18569 3.32066 11.8476C2.84271 14.5095 3.3582 17.2539 4.76985 19.5626L5.04954 20.0313L3.93077 24.1267L8.08144 23.0219Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.2708 15.7355C19.0032 15.5157 18.688 15.3611 18.3502 15.2836C18.0124 15.2061 17.6611 15.208 17.3241 15.2891C16.8207 15.5011 16.4962 16.2934 16.2054 16.6951C16.1342 16.7884 16.0319 16.8531 15.9169 16.8777C15.802 16.9022 15.6821 16.885 15.5789 16.8291C13.7281 16.1043 12.1756 14.7797 11.1709 13.0684C11.085 12.9606 11.0441 12.8239 11.0566 12.6868C11.0691 12.5497 11.134 12.4226 11.238 12.332C11.4534 12.176 11.6358 11.9792 11.7749 11.7529C11.914 11.5266 12.007 11.2751 12.0485 11.0129C12.0901 10.7507 12.0794 10.4829 12.0171 10.2248C11.9549 9.96667 11.8422 9.72339 11.6855 9.50877C11.417 8.93966 11.1261 8.12502 10.5667 7.80141C10.2938 7.67304 9.98904 7.62786 9.69051 7.67153C9.39198 7.7152 9.11302 7.84578 8.88857 8.04691C8.49829 8.38071 8.1881 8.79766 7.98092 9.26698C7.77373 9.7363 7.67482 10.246 7.69147 10.7586C7.70074 11.0479 7.73822 11.3357 7.80336 11.6178C7.98355 12.2876 8.26257 12.9269 8.63126 13.5148C8.89084 13.9711 9.17854 14.4109 9.49271 14.8316C10.5053 16.2122 11.7738 17.3864 13.2294 18.2909C13.9641 18.7498 14.7485 19.1241 15.5677 19.4068C16.4194 19.7891 17.3574 19.9392 18.2863 19.842C18.8129 19.7542 19.3109 19.5424 19.739 19.2241C20.1671 18.9058 20.5129 18.4902 20.7476 18.0119C20.8788 17.7179 20.9178 17.3911 20.8595 17.0746C20.6022 16.4273 19.7295 16.0368 19.2708 15.7355Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </li>
                )}
                {/* {siteDoc && siteDoc.linkedinLink && (
                  <li>
                    <a href={siteDoc.linkedinLink} target="_blank">
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.388187 6.8568H4.68949V20.6706H0.388187V6.8568ZM2.48638 -0.00290774C2.9811 -0.00498163 3.46529 0.139936 3.87749 0.413438C4.2897 0.686941 4.61135 1.07671 4.80163 1.53329C4.99191 1.98986 5.04225 2.49265 4.94624 2.97787C4.85023 3.46309 4.61222 3.90885 4.26239 4.2586C3.91257 4.60835 3.46671 4.84633 2.9814 4.94232C2.49608 5.03831 1.99318 4.98797 1.53651 4.79773C1.07985 4.60749 0.690003 4.28591 0.416445 3.87378C0.142887 3.46166 -0.00205236 2.97759 2.19575e-05 2.48297C0.00278331 1.82453 0.265624 1.19384 0.73131 0.728251C1.197 0.26266 1.82781 -0.000146948 2.48638 -0.00290774Z" fill="white" />
                        <path d="M7.38525 6.85674H11.4977V8.74475C11.9197 8.03133 12.529 7.44697 13.2595 7.05501C13.9899 6.66304 14.8137 6.47839 15.6417 6.52109C19.9849 6.52109 20.7822 9.37407 20.7822 13.0976V20.6706H16.4809V13.9577C16.4809 12.3529 16.4809 10.2971 14.2464 10.2971C12.0118 10.2971 11.6761 12.0382 11.6761 13.8423V20.6706H7.38525V6.85674Z" fill="white" />
                      </svg>
                    </a>
                  </li>
                )} */}
                {/* {siteDoc && siteDoc.telegramlink && (
                  <li>
                    <a href={siteDoc.telegramlink} target="_blank">
                      <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.37332 9.48496C2.37332 9.48496 12.7224 5.03014 16.3081 3.46321C17.6885 2.83461 22.3855 0.821293 22.3855 0.821293C22.3855 0.821293 24.5387 -0.0897098 24.365 2.07849C24.3043 2.9895 23.8268 6.03226 23.3492 9.36655C22.6286 14.0765 21.8472 19.2236 21.8472 19.2236C21.8472 19.2236 21.7344 20.6721 20.7099 20.9181C19.6854 21.1641 18.0184 20.0071 17.7232 19.7885C17.4281 19.5698 13.2346 16.7821 11.6458 15.3974C11.4875 15.2854 11.3588 15.1332 11.2718 14.9548C11.1849 14.7765 11.1424 14.5779 11.1485 14.3777C11.1546 14.1774 11.209 13.9821 11.3066 13.8099C11.4042 13.6378 11.5418 13.4944 11.7066 13.3932C13.8597 11.3161 16.4296 8.74705 17.9837 7.10724C18.7043 6.36021 19.4249 4.60197 16.4296 6.73372C12.1841 9.81293 7.99063 12.7008 7.99063 12.7008C7.56024 12.9077 7.0941 13.0201 6.62081 13.0311C6.14752 13.042 5.67707 12.9514 5.2384 12.7646C3.44989 12.1998 1.3575 11.4436 1.3575 11.4436C1.3575 11.4436 -0.0837127 10.5326 2.37332 9.49407V9.48496Z" fill="white" />
                      </svg>
                    </a>
                  </li>
                )} */}
                {/* <li>
                  {siteDoc && siteDoc.youtubelink && (
                    <a href={siteDoc.youtubelink} target="_blank">
                      <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.7866 5.52265C24.7866 4.10949 24.2251 2.75421 23.2257 1.75495C22.2262 0.755698 20.8706 0.194336 19.4572 0.194336H5.81892C4.40729 0.197114 3.05443 0.759712 2.05724 1.75867C1.06005 2.75762 0.499997 4.11131 0.5 5.52265V11.9208C0.499997 13.3322 1.06005 14.6859 2.05724 15.6848C3.05443 16.6838 4.40729 17.2464 5.81892 17.2492H19.4572C20.8706 17.2492 22.2262 16.6878 23.2257 15.6885C24.2251 14.6893 24.7866 13.334 24.7866 11.9208V5.52265ZM16.782 9.17278L10.6658 12.1936C10.4245 12.3194 9.61666 12.1935 9.61666 11.8789V5.62755C9.61666 5.35484 10.435 5.18701 10.6658 5.32336L16.5092 8.47001C16.7715 8.67979 17.0233 9.03642 16.782 9.17278Z" fill="white" />
                      </svg>
                    </a>
                  )}
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_panel_bottom">
          <p>
            Copyright © {new Date().getFullYear()}{" "}
            <a href="/">Stakonomics Recovery</a>, All rights Reserved
          </p>
          <ul className="footer_menu_links">
            <li>
              <Link to="/terms">Terms of service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
