import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/images/logo-light.png";
import logo_dark from "../assets/images/logo.png";
import $ from "jquery";
import ThemeToggle from "./ThemeToggle";
import { useTheme } from "./ThemeContext";

export default function Navbar(props) {
  // dispatch
  const { isAuth } = useSelector((state) => state.auth);
  const { theme } = useTheme();

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    $(document).ready(function () {
      $(".auth_btn_grp .primary_btn").click(function () {
        $("body").css({ overflow: "unset" });
      });
    });
  }

  useEffect(() => {
    loadScript();
  }, []);

  return (
    <div className="bgcolor_nav">
    <div className="container-fluid">
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <Link className="navbar-brand" to="/">
          {theme === "light" ? (
            <img src={logo} className="img-fluid brand_logo" alt="Light logo" />
          ) : (
            <img src={logo_dark} className="img-fluid brand_logo" alt="Dark logo" />
          )}
        </Link>
        {/* <ul className="navbar-nav navbar_left">
          <li className="nav-item">
            <NavLink className="nav-link" aria-current="page" to="/staking">
              Staking
            </NavLink>
          </li>
        </ul> */}
        <div className="navbar_right">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            {!isAuth && (
              <div className="offcanvas-body">
                <div className="ms-auto">
                  <div className="auth_btn_grp">
                    <Link to="/login" className="primary_btn">
                    Sign In
                    </Link>
                    <Link to="/register" className="primary_btn dark_btn">
                    Sign UP
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
          <ThemeToggle /> 
        </div>
      </nav>
    </div>
    </div>
  );
}
